<template>
  <section class="wameed-dashboard-page-content_header">
    <div
      class="
        align-items-center
        justify-content-between
        flex-wrap
        my-3
        pb-1
        px-4
        d-none d-flex
      "
    >
      <div
        class="px-0 mt-3"
        :class="
          closeSearch ? 'col-md-5 col-12 open_search_item' : 'col-md-5 col-1'
        "
        @click="openSearchItem()"
      >
        <text-input
          id="login-email"
          v-model="search"
          v-debounce:1000ms="searchItem"
          icon="search-icon"
          is-append
          input-classes="text-bold-14 text-font-main "
          name="search"
          :placeholder="$t('common.search_data')"
          field-classes="mb-0 search-form-control input-search-mobile"
        />
      </div>
      <div
        class="d-flex justify-content-end pr-0 w-sm-100"
        :class="closeSearch ? 'col-md-7 col-12' : 'col-md-7 col-11'"
      >
        <div v-if="content" class="mr-md-3 mr-1 mt-3">
          <wameed-btn
            classes="text-med-16 rounded-14 px-3 btn-filter"
            :title="$t('common.filter')"
            type="button"
            append-icon="filter-icon"
            variant="white"
            :hide-title-on-moile="true"
            @submitAction="filterOn = !filterOn"
          />
          <wameed-aside-modal
            :visible="filterOn"
            :content="content"
            @close="filterOn = !filterOn"
            @applyFilter="applyFilter"
            @resetFilter="resetFilter"
          />
        </div>
        <div class="ml-2 mt-3">
          <wameed-menu
            id="dropdown-1"
            classes=" text-med-16"
            field-classes="mb-0 search-form-control rounded-14 filter-dropdown"
            value-classes="text-med-16"
            :title="$t('common.order_by') + ' : '"
            :sub-title="orderBy.name"
            variant="white"
            :items="orderByItems"
            text="name"
            active-item="1"
            @dropdownClick="orderApplicants"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WameedMenu from "@/components/WameedMenu.vue";
import WameedBtn from "@/components/WameedBtn.vue";
import TextInput from "@/components/TextInput.vue";
import WameedAsideModal from "@/components/WameedAsideModal.vue";

export default {
  components: {
    WameedMenu,
    WameedBtn,
    TextInput,
    WameedAsideModal,
  },
  props: {
    subTitle: {
      type: String,
      default: null,
    },

    content: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      filterOn: false,
      closeSearch: false,
      orderBy: {
        name: this.$i18n.t("common.newest"),
        id: "desc",
      },
      orderByItems: [
        {
          name: this.$i18n.t("common.newest"),
          id: "desc",
        },
        {
          name: this.$i18n.t("common.older"),
          id: "asc",
        },
        {
          name: this.$i18n.t("common.alphabetical"),
          id: "name",
        },
      ],
    };
  },
  methods: {
    searchItem() {
      this.$emit("orderApplicants", {
        search: this.search,
        order_by: this.orderBy.id,
      });
    },
    applyFilter() {
      this.$emit("applyFilter");
    },
    resetFilter() {
      this.$emit("resetFilter");
    },
    orderApplicants(item) {
      if (item) {
        this.orderBy = item;
      }
      this.$emit("orderApplicants", {
        search: this.search,
        order_by: this.orderBy.id,
      });
    },
    openSearchItem() {
      this.closeSearch = true;
    },
  },
};
</script>
