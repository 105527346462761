<template>
  <div class="d-flex justify-content-md-between justify-content-center pagination-container my-4">
    <b-row class="w-100">
      <b-col sm="12" md="6" class="p-0 order-2 order-md-1 d-md-block d-flex justify-content-center">
        <b-pagination
          :value="value"
          first-number
          last-number
          :per-page="per_page"
          :total-rows="totalItems"
          @input="updateValue($event)"
          @change="changePage($event)"
        >
          <template #prev-text>
            <arrow-prev-icon />
          </template>
          <template #next-text>
            <arrow-next-icon />
          </template>
        </b-pagination>
      </b-col>
      <b-col sm="12" md="6" class="p-0 order-1 order-md-2 mb-md-0 mb-5 d-md-block d-flex justify-content-center">
        <div class="float-right no-m-dropdown">
          <wameed-menu
            classes=" text-med-16 "
            field-classes="mb-0  "
            value-classes="text-med-16"
            :title="$t('offers_page.show_from_result') + ' - ' + per_page"
            variant="white"
            :items="items ? items : dropdown_items"
            text="title"
            @dropdownClick="changeCurrentPage"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WameedMenu from './WameedMenu.vue';

export default {
  components: { WameedMenu },
  props: {
    page: {
      type: Number,
      default: null,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    per_page: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dropdown_items: [
        {
          title: `20 - 1 ${this.$i18n.t('offers_page.from_data')}`,
          id: 20,
        },
        {
          title: `40 - 1 ${this.$i18n.t('offers_page.from_data')}`,
          id: 40,
        },
        {
          title: `60 - 1 ${this.$i18n.t('offers_page.from_data')}`,
          id: 60,
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    changePage(value) {
      this.$emit('changePage', value);
    },
    changeCurrentPage(value) {
      this.$emit('changeCurrentPage', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
